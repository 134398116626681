:root {
  --font-monospace: "Courier New", "Courier", monospace;
  --color-border: #84a59d;
}

.appContainer {
  max-width: 2000px;
  margin: 0 auto;
}

.canvasContainer {
  position: absolute;
  height: 150vh;
  width: 100%;
  z-index: 1;

  opacity: 0;
  animation: fade-in 1s linear 1.75s forwards;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.canvas {
  background: white;
}

.bodyContainer {
  display: inline-block;
  width: 100%;
  position: relative;
  z-index: 2;
  padding: 30px 20px 50px;
  box-sizing: border-box;

  word-wrap: break-word;
}

.gradient {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 450px;
  z-index: 2;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.9) 50%,
    rgba(255, 255, 255, 0)
  );
}

.body {
  max-width: 600px;
}

.body h1 {
  font-family: "Newsreader", serif;
  font-size: 2.5em;
  margin-top: 10px;
  margin-bottom: 3px;
}

.fasta {
  float: left;
  font-size: 12px;
  line-height: 1.3em;
  color: #6a6a6a;
  word-break: break-word;
  max-width: 475px;

  font-family: var(--font-monospace);
  opacity: 0;
  animation: fade-in 1s linear 2.5s forwards;
}

.subject {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.4em;
  padding-left: 2px;

  font-family: var(--font-monospace);
}

.sample {
  float: left;
  margin-top: 10px;
  font-size: 15px;
  height: 0;
  overflow: hidden;
  width: 460px;
  max-width: 100%;

  font-family: var(--font-monospace);
  animation: height-in 0.3s cubic-bezier(0.33, 1, 0.68, 1) 1.5s forwards;
}

@media (min-width: 1025px) {
  .sample {
    animation: height-in-wide-desktop 0.3s cubic-bezier(0.33, 1, 0.68, 1) 1.5s
      forwards;
  }
}

@keyframes height-in {
  0% {
    height: 0;
    border-bottom-color: transparent;
  }
  10% {
    border-bottom-color: var(--color-border);
  }
  100% {
    height: 200px;
    border-bottom-color: var(--color-border);
  }
}

@keyframes height-in-wide-desktop {
  0% {
    height: 0;
    border-bottom-color: transparent;
  }
  10% {
    border-bottom-color: var(--color-border);
  }
  100% {
    height: 180px;
    border-bottom-color: var(--color-border);
  }
}

.sample h4 {
  display: inline-block;
  padding: 8px 0;
  margin-bottom: 0;

  border-top: 1px solid var(--color-border);
  font-weight: bold;
}

.sample p {
  border-top: 1px solid var(--color-border);
  border-bottom: 1px solid var(--color-border);
  margin: 0;
  padding: 10px 0;
}

.nobreak {
  display: inline-block;
}

@media (min-width: 768px) {
  .canvasContainer {
    width: 50%;
    height: 100vh;
  }

  .bodyContainer {
    box-sizing: border-box;
    display: grid;
    height: 100vh;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(20, 1fr);
  }

  .body {
    max-width: none;
    grid-column: 7 / span 5;
    grid-row: 10 / span 10;
  }

  .gradient {
    display: none;
  }

  .sample h4 {
    padding: 8px 20px;
  }

  .sample p {
    padding: 10px 20px;
  }
}

@media (min-width: 1025px) {
  .body {
    grid-column: 7 / span 4;
    grid-row: 10 / span 10;
  }
}

/* https://css-tricks.com/snippets/css/typewriter-effect/ */

.typewriter-0,
.typewriter-1,
.typewriter-2 {
  float: left;
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  width: 0;
}

.typewriter-0 {
  animation: typing 0.5s steps(8, end) forwards;
}

.typewriter-1 {
  animation: typing 0.5s steps(15, end) 0.5s forwards;
}

.typewriter-2 {
  animation: typing 0.5s steps(17, end) 1s forwards;
}

/* The typing effect */
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

.icons {
  float: right;
  position: relative;
  top: 23px;
  font-size: 26px;
}

.icons a {
  color: var(--color-border);
}

.icons a:hover {
  opacity: 0.8;
}

.icons a:not(:last-child) {
  margin-right: 10px;
}
